import React, { useState } from "react";
import {
  Button,
  Box,
  Group,
  Table,
  Checkbox,
  Select,
  Grid,
  NumberInput,
  TextInput,
  Modal,
  Loader
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { DateInput } from "@mantine/dates";
import _ from "lodash";
import "./App.css";

const SERVICE_URL = process.env.REACT_APP_SERVICE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const HEADERS = { "Content-Type": "Application/json", "x-api-key": API_KEY };

const yearFromNow = () => {
  const now = new Date();
  now.setFullYear(now.getFullYear() + 1);
  now.setDate(now.getDate() - 1);
  return now;
};

function App() {
  const [products, setProducts] = useState([]);
  const [disableGetProducts, setDisableGetProducts] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(yearFromNow());
  const [total, setTotal] = useState(0);
  const [success, setSuccess] = useState(false);
  const getProducts = () => {
    load(true);
    fetch(`${SERVICE_URL}products`, { headers: HEADERS })
      .then((res) => res.json())
      .then((records) => {
        const productRows = records.map((e) => {
          return {
            id: e.Id,
            name: e.Name,
            pricingRecord: {},
            qty: 1,
            soptions: e.ProductSellingModelOptions.records.map((option) => {
              return {
                value: `${e.Id}_${option.ProductSellingModel.Id}`,
                label: option.ProductSellingModel.Name,
                product: e.Id
              };
            }),
            sellingOption:
              e.ProductSellingModelOptions.records[0].ProductSellingModel.Id
          };
        });
        setProducts(productRows);
        setDisableGetProducts(true);
        load(false);
      })
      .catch((err) => {
        console.log(err);
        load(false);
      });
  };

  const changeSellingOption = (value) => {
    const productId = value.split("_")[0];
    const optionId = value.split("_")[1];

    products.find((e) => e.id === productId).sellingOption = optionId;
    setProducts(_.cloneDeep(products));
  };

  const selectOption = (evt) => {
    const productId = evt.target.dataset.id;
    products.find((e) => e.id === productId).selected = evt.target.checked;
    setProducts(_.cloneDeep(products));
  };

  const changeQty = (val, row) => {
    products.find((e) => e.id === row).qty = val;
    setProducts(_.cloneDeep(products));
  };

  const setStart = (val) => {
    setStartDate(val);
  };
  const setEnd = (val) => {
    setEndDate(val);
  };
  const getPrices = () => {
    load(true);
    fetch(`${SERVICE_URL}prices`, {
      method: "POST",
      body: JSON.stringify({
        startDate: startDate.toISOString().split("T")[0],
        endDate: endDate.toISOString().split("T")[0],
        products: products.filter((e) => e.selected)
      }),
      headers: HEADERS
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        let totalPrice = 0;
        setTotal(0);
        for (const record of result.records.filter((e) => e.record.ProductId)) {
          console.log(record.record.ProductId);
          const product = products.find(
            (e) => e.id === record.record.ProductId
          );
          product.pricingRecord = { ...record.record };
          totalPrice +=
            product.pricingRecord.BillingFrequency !== "Monthly"
              ? product.pricingRecord.TotalPrice
              : product.pricingRecord.NetUnitPrice;
        }
        console.log(products);
        setProducts(_.cloneDeep(products));
        console.log(totalPrice);
        setTotal(totalPrice);
        load(false);
      })
      .catch((err) => {
        console.log(err);
        load(false);
      });
  };

  const checkOut = () => {
    setShowCheckout(true);
  };

  const load = (loading) => {
    setLoading(loading);
  };

  const form = useForm({
    initialValues: {
      firstName: "Kobe",
      lastName: "Bernar",
      company: "Reditus",
      email: "kobe@reditus.be",
      ccn: "1111 1111 1111 1111",
      cvv: "123",
      termsOfService: false
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email")
    }
  });

  const createOrder = (formValues) => {
    load(true);
    let productsInput = [];
    products
      .filter((e) => e.selected)
      .forEach((product) => {
        productsInput.push({
          PricebookEntryId: product.pricingRecord.PricebookEntryId,
          Product2Id: product.id,
          Quantity: product.qty,
          TaxTreatmentId: "1ttDp000000siTyIAI",
          ServiceDate: startDate.toISOString().split("T")[0],
          EndDate: product.pricingRecord.EndDate,
          BillingFrequency2: product.pricingRecord.BillingFrequency || null,
          NetUnitPrice: product.pricingRecord.NetUnitPrice,
          UnitPrice: product.pricingRecord.StartingUnitPrice,
          TotalLineAmount: product.pricingRecord.TotalLineAmount || null,
          PricingTermCount: product.pricingRecord.PricingTermCount || null,
          PeriodBoundaryStartMonth:
            product.pricingRecord.PeriodBoundaryStartMonth || null,
          PeriodBoundaryDay: product.pricingRecord.PeriodBoundaryDay || null,
          PeriodBoundary: product.pricingRecord.PeriodBoundary || null
        });
      });

    const requestObject = {
      userInfo: {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email,
        company: formValues.company
      },
      order: {
        EffectiveDate: startDate.toISOString().split("T")[0],
        EndDate: endDate.toISOString().split("T")[0],
        Pricebook2Id: "01sDp000000GqBVIA0",
        Status: "Draft",
        CurrencyIsoCode: "USD"
      },
      products: productsInput,
      paymentDetails: {
        cardNumber: formValues.ccn.replace(/\s/g, ""),
        expiryMonth: "01",
        expiryYear: "9999",
        cardType: "Visa",
        cardHolderFirstName: formValues.firstName,
        cardHolderLastName: formValues.lastName,
        cvv: formValues.cvv,
        autoPay: true
      }
    };
    console.log(requestObject);
    fetch(`${SERVICE_URL}buy-now`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      headers: HEADERS
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        load(false);
        if (result[0].isSuccess) {
          setSuccess(true);
        }
      });
  };

  return (
    <div className="App">
      <Modal
        opened={showCheckout}
        onClose={() => {
          setShowCheckout(false);
          setSuccess(false);
        }}
      >
        <Box>
          {!success && (
            <form onSubmit={form.onSubmit((values) => createOrder(values))}>
              <TextInput
                withAsterisk
                label="First Name"
                {...form.getInputProps("firstName")}
              />
              <TextInput
                withAsterisk
                label="Last Name"
                {...form.getInputProps("lastName")}
              />
              <TextInput
                withAsterisk
                label="Company"
                {...form.getInputProps("company")}
              />
              <TextInput
                withAsterisk
                label="Email"
                placeholder="your@email.com"
                {...form.getInputProps("email")}
              />
              <TextInput
                withAsterisk
                label="Card Number"
                {...form.getInputProps("ccn")}
              ></TextInput>
              <TextInput
                withAsterisk
                label="CVV"
                {...form.getInputProps("cvv")}
              ></TextInput>
              <Checkbox
                mt="md"
                label="Agree to TOS"
                {...form.getInputProps("termsOfService", { type: "checkbox" })}
              />
              <Group position="right" mt="md">
                <Button type="submit" disabled={loading}>
                  Place Order {parseFloat(total).toFixed(2)} USD
                </Button>
              </Group>
            </form>
          )}
          {success && (
            <h1>
              Thank you for your Order!
              <br /> You will receive an e-mail when it is processed
            </h1>
          )}
          {loading && <Loader />}
        </Box>
      </Modal>
      <Grid>
        <Grid.Col span={1}>
          <Button onClick={getProducts} disabled={disableGetProducts}>
            Get Products
          </Button>
        </Grid.Col>
        <Grid.Col span={1}>
          <Button onClick={getPrices}>Get Prices</Button>
        </Grid.Col>
        <Grid.Col span={1}>
          <Button onClick={checkOut}>Check Out</Button>
        </Grid.Col>
        <Grid.Col span={1}>
          <DateInput
            onChange={setStart}
            label="Start Date"
            defaultValue={new Date()}
          ></DateInput>
        </Grid.Col>
        <Grid.Col span={1}>
          <DateInput
            onChange={setEnd}
            label="End Date"
            defaultValue={yearFromNow()}
          ></DateInput>
        </Grid.Col>
        {loading && (
          <Grid.Col span={1}>
            <Loader />
          </Grid.Col>
        )}
        <Grid.Col span={10}></Grid.Col>
        <Grid.Col span={12}>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Qty</th>
                <th>Selling Model</th>
                <th>Unit Price</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {products.map((row) => {
                return (
                  <tr key={row.id}>
                    <td>
                      <Checkbox data-id={row.id} onChange={selectOption} />
                    </td>
                    <td>{row.name}</td>
                    <td>
                      <NumberInput
                        defaultValue={1}
                        min={1}
                        onChange={(val) => changeQty(val, row.id)}
                      ></NumberInput>
                    </td>
                    <td>
                      <Select
                        label=""
                        placeholder="Select"
                        data={row.soptions}
                        onChange={changeSellingOption}
                        defaultValue={row.soptions[0].value}
                      ></Select>
                    </td>
                    <td>{row.pricingRecord.NetUnitPrice}</td>
                    <td>{row.pricingRecord.TotalPrice}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Grid.Col>
      </Grid>
    </div>
  );
}

export default App;
